import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import SEO from "components/seo"
import { StateContext } from "components/Context"
import TransitionWrapper from "components/TransitionWrapper"
import Container from "components/Container"
import { HomeTimeline, FooterTimeline } from "utils/Animations"

const Home = ({ data, transitionStatus }) => {
  const {
    state: { logoMesh, sparks, isLoaded, isMobile, homeTl },
    dispatch,
  } = useContext(StateContext)

  useEffect(() => {
    if (window.locoscroll) {
      window.locoscroll.scrollTo(0, 0, 0, [0.25, 0.0, 0.35, 1.0], true, () => {
        window.locoscroll.stop()
      })
    }

    // CREATE HOME TIMELINE
    if (!logoMesh || homeTl.totalDuration() > 0) return
    homeTl.add(HomeTimeline(logoMesh, sparks, isMobile))
    homeTl.add(FooterTimeline())
    dispatch({
      type: "SET_CURRENT_TIMELINE_DURATION",
      value: homeTl.totalDuration(),
    })

    window.homeTl = homeTl
  }, [logoMesh, dispatch, homeTl, isMobile, sparks])

  const onEntering = () => {
    homeTl.timeScale(1).play()
  }

  const onEntered = () => {
    if (!isLoaded) return
    homeTl.timeScale(1).play()
  }

  const onExiting = () => {
    homeTl
      .timeScale(1.5)
      .reverse()
      .then(tl => tl.clear(true))
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: children => (
        <span
          className="u-outline u-outline--hover-white pt-2 pb-2 transition duration-500 ease-in-out"
          data-effect-text="1"
        >
          {children}
        </span>
      ),
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="underline"
          title={children}
          href={node.data.uri}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <span className="block relative overflow-hidden mb-2 md:mb-0 h-8 md:h-12">
          <span className="absolute" data-anim-line="">
            {children}
          </span>
        </span>
      ),
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <>
      <SEO lang={"en"} pageTitle={data.contentfulPageHome.title} />
      <TransitionWrapper
        page="home"
        isLoaded={isLoaded}
        transitionStatus={transitionStatus}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
      >
        <Container className="h-screen overflow-hidden">
          <section className="h-full w-full flex inset-y-0 items-end md:items-center">
            <div className="relative w-full">
              <h1 className="text-2xl sm:text-3xl">
                {renderRichText(
                  data.contentfulPageHome.sections.description,
                  options
                )}
              </h1>
              {data.contentfulPageHome.sections.images && (
                <img
                  data-effect-image="1"
                  className="absolute w-2/5 md:w-1/5 opacity-0 pointer-events-none -z-1"
                  src={data.contentfulPageHome.sections.images.fixed.src}
                  alt={data.contentfulPageHome.sections.images.title}
                />
              )}
            </div>
          </section>
        </Container>
      </TransitionWrapper>
    </>
  )
}

export default Home

export const query = graphql`
  query HomePageQuery {
    contentfulPageHome(sections: { node_locale: { eq: "en" } }) {
      title
      createdAt
      sections {
        description {
          raw
        }
        images {
          title
          fixed(
            resizingBehavior: SCALE
            quality: 90
            width: 300
            toFormat: JPG
          ) {
            src
          }
        }
      }
    }
  }
`
